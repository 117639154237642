import React, { useState } from 'react'
import { useRHTable } from '../../../../hooks/useRHTable'
import useModal from '../../../../hooks/useModal'
import HeaderLayout from '../Layout/HeaderLayout'
import GlobalFilter from '../RHTable/components/GlobalFilter'
import { RHTable } from '../RHTable'
import NoData from '../../../../components/NoData/NoData'
import { RespondentEditModal } from './RespondentEditModal'
import RespondentSelectModal from './RespondentSelectModal'
import ExportButton from './Buttons/ExportButton'
import { useEditRespondent } from '../../../../hooks/useEditRespondent'
import CreateRespondentButton from './Buttons/CreateRespondentButton'
import DeleteAllButton from './Buttons/DeleteAllButton'
import { useTranslation } from 'react-i18next'
import AddRespondentButton from './Buttons/AddRespondentButton'
import { ConfirmDeleteModal } from '@olystic/design_system_3'
import { RHStats } from '../RHStats/RHStats'

function RespondentGroupList({ base, rhData }) {
  const { t } = useTranslation()
  const [searchString, setSearchString] = useState('')
  const { respondents, deleteManyRespondent, addRespondents } = rhData
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({
    show: false,
    id: '',
  })
  const [selectedRespondent, setSelectedRespondent] = useState(null)
  const instance = useRHTable(respondents.data || {})
  const editRespondent = useEditRespondent(rhData)
  const hasSelection = !!instance.selectedFlatRows.length
  const addRespondentModal = useModal(handleAddRespondents)

  function handleShowConfirmDeleteModal(id, respondent) {
    if (respondent) setSelectedRespondent(respondent)
    setShowConfirmDeleteModal({ show: true, id })
  }

  function handleDelete(id) {
    if (id === 'selection') handleDeleteSelection()
    if (id === 'respondent') handleDeleteRespondent(selectedRespondent)
    setShowConfirmDeleteModal({ show: false, id: '' })
  }

  function handleDeleteRespondent(respondent) {
    deleteManyRespondent.mutate([respondent._id])
  }

  function handleDeleteSelection() {
    const ids = instance.selectedFlatRows.map((row) => row.original._id)
    deleteManyRespondent.mutate(ids)
    setShowConfirmDeleteModal(false)
  }

  function handleAddRespondents(rows) {
    addRespondents.mutate(rows.map((r) => r.original._id))
  }

  return (
    <>
      <HeaderLayout>
        <HeaderLayout.Group>
          <AddRespondentButton onClick={addRespondentModal.onShow} />
          <CreateRespondentButton
            onEdit={editRespondent.onEdit}
            variant='secondary'
          />
        </HeaderLayout.Group>
        <HeaderLayout.Group>
          <GlobalFilter value={searchString} onChange={setSearchString} />
        </HeaderLayout.Group>

        <HeaderLayout.Group>
          <RHStats baseId={base.id} />
          <ExportButton instance={instance} />
          <DeleteAllButton
            disabled={!hasSelection}
            onClick={() => handleShowConfirmDeleteModal('selection')}
          />
        </HeaderLayout.Group>
      </HeaderLayout>
      <RHTable
        instance={instance}
        globalFilter={searchString}
        loading={respondents.isLoading}
        baseCategory={base.base.data?.category}
        noDataAction={
          <div className='w-50 mx-auto mt-5'>
            <NoData
              title={t('rh.no_data.title')}
              text={t('rh.no_data.text')}
              // cta={<ExportButton instance={instance} variant="primary" />}
            />
          </div>
        }
        onEdit={editRespondent.onEdit}
        onDelete={(respondent) =>
          handleShowConfirmDeleteModal('respondent', respondent)
        }
      />
      <RespondentEditModal {...editRespondent.modalProps} />
      {base.base.data?.parentGroup && (
        <RespondentSelectModal
          baseId={base.base.data?.parentGroup}
          {...addRespondentModal.modalProps}
        />
      )}
      <ConfirmDeleteModal
        modalOpen={showConfirmDeleteModal.show}
        closeModal={() => setShowConfirmDeleteModal(false)}
        plural={instance.selectedFlatRows.length > 1}
        contact={false}
        onConfirm={() => handleDelete(showConfirmDeleteModal.id)}
      />
    </>
  )
}

export default RespondentGroupList
