import React from 'react'
// Components
import Section from '../components/layout/section'
import FormFeedback from '../features/feedback/FeedbackForm/Form'

export default function QuickFeedback() {
  return (
    <Section className="my-8">
      <FormFeedback />
    </Section>
  )
}
