import React from 'react'
import { Button } from 'react-bootstrap'
import Select from 'react-select'
import './rhMappingModal.scss'
import { useTranslation } from 'react-i18next'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import { Scrollbar } from '@olystic/design_system_3'

function Mapping({ columns, mapping, options, handleFilters }) {
  const { t } = useTranslation()
  let used = Object.values(mapping).map((o) => o.value)
  const availOptions = options.filter((o) => !used.includes(o.value))

  const haveNoMatchMapping = columns.find(
    (entry) => !Object.keys(mapping).includes(entry[0]),
  )

  return (
    <div>
      <h4>{t('component.rhMapping.heading')}</h4>
      <p className='pb-3 text-black-50'>
        {t('component.rhMapping.description')}
      </p>
      {haveNoMatchMapping && (
        <p className='mt-n3 pb-3 text-danger'>
          {t('component.rhMapping.warningMessage')}
        </p>
      )}

      <div className=' px-5 mb-3 mapping-row'>
        <span>{t('component.rhMapping.col_label1')}</span>
        <span className='text-center'>
          {t('component.rhMapping.col_label2')}
        </span>
        <span className='text-center'>
          {t('component.rhMapping.col_label3')}
        </span>
      </div>
      <Scrollbar
        style={{
          maxHeight: '50vh',
          paddingRight: '2rem',
          marginRight: '-2rem',
        }}
      >
        <div>
          {columns.map((entry, id) => (
            <div className='content-row' key={id}>
              <span>
                <span id={`label-${id}`}>{entry[0]}</span>
              </span>
              <span className='text-center'>
                <div className='d-flex flex-column px-5'>
                  <span className='text-truncate'>{entry[1][0]}</span>
                  <span className='text-truncate opacity-50'>
                    {entry[1][1]}
                  </span>
                  <span className='text-truncate opacity-25'>
                    {entry[1][2]}
                  </span>
                </div>
              </span>
              <div>
                <div className='d-flex align-items-center gap-2'>
                  {mapping[entry[0]] ? (
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className='text-success'
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      className='text-danger'
                    />
                  )}

                  <Select
                    onChange={(e) => handleFilters(entry[0], e)}
                    className='select w-100'
                    value={
                      mapping[entry[0]] || {
                        value: 'none',
                        label: t('common.none'),
                      }
                    }
                    options={availOptions}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Scrollbar>
    </div>
  )
}

function RHMapping({
  columns,
  options,
  mapping,
  showErrorMessage,
  onShowErrorMessage,
  onFilter,
}) {
  const { t } = useTranslation()

  return (
    <>
      {showErrorMessage ? (
        <div>
          <p>{t('component.rhMapping.error.text2')}</p>

          <Button
            className='float-end mt-4'
            onClick={() => onShowErrorMessage(false)}
          >
            {t('component.rhMapping.error.text1')}
          </Button>
        </div>
      ) : (
        columns &&
        options && (
          <Mapping
            handleFilters={onFilter}
            columns={columns}
            options={options}
            mapping={mapping}
            showErrorMessage={showErrorMessage}
          />
        )
      )}
    </>
  )
}

export default RHMapping
