import React, { useEffect, useState } from 'react'
import { useRHTable } from '../../../../hooks/useRHTable'
import { useRHImport } from '../../../../hooks/useRHImport'
import HeaderLayout from '../Layout/HeaderLayout'
import GlobalFilter from '../RHTable/components/GlobalFilter'
import { RHTable } from '../RHTable'
import NoData from '../../../../components/NoData/NoData'
import RHImport from '../RHManagement/RHImport'
import { RespondentEditModal } from './RespondentEditModal'
import { useEditRespondent } from '../../../../hooks/useEditRespondent'
import CreateRespondentButton from './Buttons/CreateRespondentButton'
import ImportButton from './Buttons/ImportButton'
import ExportButton from './Buttons/ExportButton'
import DeleteAllButton from './Buttons/DeleteAllButton'
import backend from '../../../../services/backend'
import { useSelector } from 'react-redux'
import { surveySelector } from '../../../../redux/reducers/surveys'
import EmailModal from './EmailModal'
import useModal from '../../../../hooks/useModal'
import { useTranslation } from 'react-i18next'
import { EmailTypes } from './EmailTypes'
import { useQueryClient } from '@tanstack/react-query'
import { ConfirmDeleteModal, notification } from '@olystic/design_system_3'
import RespondentIndividualProcess from './RespondentIndividualProcess'

function RespondentSurveyList({ base, rhData }) {
  const { t } = useTranslation()
  const [searchString, setSearchString] = useState('')
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({
    show: false,
    id: '',
  })
  const [selectedRespondent, setSelectedRespondent] = useState(null)
  const { respondents, deleteManyRespondent } = rhData
  const survey = useSelector((state) =>
    surveySelector.selectById(state, base.isSurvey),
  )
  const instance = useRHTable(respondents.data || {}, survey?.processOnSubmit)
  const { onImport, rhImportProps } = useRHImport(base.id)
  const editRespondent = useEditRespondent(rhData)
  const hasSelection = !!instance.selectedFlatRows.length
  const [currentEmail, setCurrentEmail] = useState(null)
  const { onShow: onShowEmail, modalProps: emailModelProps } =
    useModal(handleSendEmail)
  const queryClient = useQueryClient()

  const [canAccessSuivi, setCanSuivi] = useState(false)

  useEffect(() => {
    setCanSuivi(false)
    const getSuivi = async (respondentId) => {
      const data = await backend.rh.getSuivi(respondentId)
      if (data.suivi === 2) setCanSuivi(true)
    }

    if (instance.selectedFlatRows.length === 1) {
      getSuivi(instance.selectedFlatRows[0].original._id)
    }
  }, [instance.selectedFlatRows])

  function handleShowConfirmDeleteModal(id, respondent) {
    if (respondent) setSelectedRespondent(respondent)
    setShowConfirmDeleteModal({ show: true, id })
  }

  function handleDelete(id) {
    if (id === 'selection') handleDeleteSelection()
    if (id === 'respondent') handleDeleteRespondent(selectedRespondent)
    setShowConfirmDeleteModal({ show: false, id: '' })
  }

  function handleDeleteRespondent(respondent) {
    deleteManyRespondent.mutate([respondent._id])
  }

  function handleDeleteSelection() {
    deleteManyRespondent.mutate(
      instance.selectedFlatRows.map((row) => row.original._id),
    )
  }

  function handleAccessQuestionnaire(respondent) {
    backend.rh.getQuestionnaireLink(survey._id, respondent._id).then((res) => {
      window.open(res.link, '_blank')
    })
  }

  function handleCopyLink(respondent) {
    backend.rh.getQuestionnaireLink(survey._id, respondent._id).then((res) => {
      try {
        navigator.clipboard && navigator.clipboard.writeText(res.link)
        notification.success(t('notification.copy_link'), 'lg')
      } catch (e) {}
    })
  }

  function handleCreateEmail(rows, template) {
    const respondentIds = rows.map((row) => row?.original?._id || row)
    backend.rh.getEmail(template, survey._id, respondentIds).then((mails) => {
      const links = mails.map((mail) => {
        const match = mail.html.match(/href="([^"]+)"/)
        return {
          to: mail.to,
          link: match ? match[1] : null,
        }
      })
      const toEmails = mails.map((mail) => mail.to).join(', ')
      const baseEmail = mails[0]
      const consolidatedEmail = {
        to: toEmails,
        from: baseEmail.from,
        subject: baseEmail.subject,
        html: baseEmail.html.replace(/href="[^"]+"/g, 'href="{link}"'),
      }
      setCurrentEmail({ ...consolidatedEmail, respondentIds, links, template })
      onShowEmail()
    })
  }

  async function handleSendEmail(payload) {
    const { respondentIds, template, links, ...mail } = payload

    const sendEmailPromises = links.map((link) => {
      const email = {
        ...mail,
        to: link.to,
        html: mail.html.replace('{link}', link.link),
      }
      return backend.rh.sendEmail(email)
    })

    try {
      await Promise.all(sendEmailPromises)
      if (template === EmailTypes.ENQUETE_INDIVIDUELLE_LANCEMENT) {
        for (const id of respondentIds) {
          await backend.rh.setSuivi(id, 1)
        }
        await queryClient.invalidateQueries(['suivi'])
      }
    } catch (error) {
      console.error('An error occurred:', error)
    }

    setCurrentEmail(null)
  }

  async function handleCreateMailInterviewer() {
    const mail = {
      to: '',
      from: { email: '', name: '' },
      subject: t('rh.respondent_individuelleList.mail_subject'),
    }

    const respondentIds = instance.selectedFlatRows.map(
      (row) => row.original._id,
    )
    const liens = await backend.rh.getLinks(respondentIds, survey._id)
    mail.html =
      '<p>Bonjour,</p><br/>' +
      '<p>Je vous prie de trouver ci-joint toutes les informations nécessaires pour la passation des entretiens de départ. ' +
      'Il suffit de copier le lien du questionnaire puis de le coller dans votre navigateur internet pour y accéder et débuter la passation. ' +
      'N’oubliez pas de cliquer sur « Envoyer » en fin de questionnaire. ' +
      'Cordialement,</p><br/>' +
      instance.selectedFlatRows
        .map(
          (row) =>
            `<p>Prénom : ${row.original?.rhData?.ps_prenom || ''}</p>` +
            `<p>Nom : ${row.original?.rhData?.ps_nom || ''}</p>` +
            `<p>Email : ${row.original?.email || ''}</p>` +
            `<p>Date de sortie : ${
              row.original?.rhData?.ps_sortie?.date || ''
            }</p>` +
            `<p>Lien : ${liens[row.original._id] || ''}</p><br/>`,
        )
        .join('\n')

    setCurrentEmail({ ...mail, template: 'interviewer' })
    onShowEmail()
  }

  return (
    <>
      <RespondentIndividualProcess
        selectedRespondent={instance.selectedFlatRows}
        canAccessSuivi={canAccessSuivi}
        onAccesQuestionnaire={handleAccessQuestionnaire}
        onCreateMailInterviewer={handleCreateMailInterviewer}
        onCreateEmail={handleCreateEmail}
      />
      <HeaderLayout>
        <HeaderLayout.Group>
          <GlobalFilter value={searchString} onChange={setSearchString} />
        </HeaderLayout.Group>
        <HeaderLayout.Group>
          <CreateRespondentButton onEdit={editRespondent.onEdit} />
          <ImportButton onImport={onImport} />
          <ExportButton instance={instance} />
          <DeleteAllButton
            disabled={!hasSelection}
            onClick={() => handleShowConfirmDeleteModal('selection')}
          />
        </HeaderLayout.Group>
      </HeaderLayout>
      <RHTable
        instance={instance}
        globalFilter={searchString}
        loading={respondents.isLoading}
        noDataAction={
          <div className='w-50 mx-auto mt-5'>
            <NoData
              title={t('rh.no_data.title')}
              text={t('rh.no_data.text')}
              cta={
                <ImportButton
                  instance={instance}
                  variant='primary'
                  onImport={onImport}
                />
              }
            />
          </div>
        }
        onEdit={editRespondent.onEdit}
        onDelete={(respondent) =>
          handleShowConfirmDeleteModal('respondent', respondent)
        }
        onQuestionnaire={
          survey?.processOnSubmit ? handleAccessQuestionnaire : null
        }
        onLinkCopy={handleCopyLink}
        onSendEmail={handleCreateEmail}
      />
      <RHImport {...rhImportProps} />
      <RespondentEditModal {...editRespondent.modalProps} />
      <EmailModal {...emailModelProps} email={currentEmail} />
      <ConfirmDeleteModal
        modalOpen={showConfirmDeleteModal.show}
        closeModal={() => setShowConfirmDeleteModal(false)}
        plural={instance.selectedFlatRows.length > 1}
        contact={false}
        onConfirm={() => handleDelete(showConfirmDeleteModal.id)}
        secureText={t('rh.respondent_surveyList.confirmDeleteText')}
        warningText={t('rh.respondent_surveyList.warningText')}
      />
    </>
  )
}

export default RespondentSurveyList
