import { useQuery } from '@tanstack/react-query'
import backend from '../services/backend'
import { useMemo } from 'react'

export function useSubscription() {
  const subscriptionQuery = useQuery(['subscription'], async () => {
    const res = await backend.subscriptions.getSubscription()
    return res.data
  })

  const { canCreateBase, canCreateGroup } = useMemo(() => {
    let canCreateBase, canCreateGroup
    if (subscriptionQuery.data) {
      const options = subscriptionQuery.data.level.options
      canCreateBase = options.rh.multiple
      canCreateGroup = options.rh.groupe
    }
    return { canCreateBase, canCreateGroup }
  }, [subscriptionQuery])

  return { subscriptionQuery, canCreateBase, canCreateGroup }
}
