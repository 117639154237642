import React from 'react'
import PropTypes from 'prop-types'
import { questionnairesProps } from '@olystic/common/proptypes/questionnaire.mjs'
import useScheduler from '../../hooks/useScheduler'
import { QuestionnaireCard as QuestionnaireCardUI } from '@olystic/design_system_3'

QuestionnaireCard.propTypes = {
  data: questionnairesProps.isRequired,
  onShowPurchingModal: PropTypes.func,
  onShowQuestionsModal: PropTypes.func,
  isDraggable: PropTypes.bool,
}

QuestionnaireCard.defaultProps = {
  onShowPurchingModal: () => {},
  onShowQuestionsModal: () => {},
  isDraggable: false,
}

export default function QuestionnaireCard({
  data,
  onShowPurchingModal,
  onShowQuestionsModal,
  isDraggable,
}) {
  const { toggleScheduler } = useScheduler()
  const [dragging, setDragging] = React.useState(false)
  const [ghostId, setGhostId] = React.useState('')

  const { actif, nbQuestions } = data

  const handleDragStart = (e, id, label, type) => {
    if (!actif || !isDraggable) return
    setDragging(true)
    toggleScheduler(true)
    let dragImage = document.createElement('div')
    dragImage.id = 'ghostEnquete--' + type
    setGhostId(dragImage.id)
    document.body.appendChild(dragImage)
    e.dataTransfer.setDragImage(dragImage, 0, 0)
    e.dataTransfer.setData('text/questionnaire', id)
    e.dataTransfer.setData('text/questionnaireName', label)
    e.dataTransfer.setData('text/questionnaireType', type)
    e.dataTransfer.setData('text/nbQuestions', nbQuestions)
  }

  const handleDragEnd = () => {
    setDragging(false)
    let ghostEnquete = document.getElementById(ghostId)
    ghostEnquete && ghostEnquete.remove()
  }

  return (
    <QuestionnaireCardUI
      data={data}
      onShowPurchingModal={onShowPurchingModal}
      onShowQuestionsModal={onShowQuestionsModal}
      isDraggable={isDraggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      dragging={dragging}
    />
  )
}
