import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { surveySelector } from '../../../redux/reducers/surveys'
import { updateOne as updateOneWaitingSurvey } from '../../../redux/reducers/waitingSurveys'
import backend from '../../../services/backend'
// Components
import useDeleteSurvey from '../../../hooks/useDeleteSurvey.js'
import moment from 'moment'
import { mappingSurvey } from '@olystic/common/survey.mjs'
import {
  Button,
  DropdownButton,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { surveyProps } from '@olystic/common/proptypes/survey'
import { updateDatesWithType } from '../../../lib/date/updateDateWithType'
import { useSurveyTarget } from '../../../hooks/useSurveyTarget'
import { fixRelance } from '../../../lib/date/fixRelance'
import TypeEnquetes from '../../../lib/surveyDates/typeEnquetes'
import { ButtonWithIcon, notification } from '@olystic/design_system_3'
import { DURATION_BY_TYPE } from '../../../lib/constants'
import { Scrollbar } from '@olystic/design_system_3'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash as falTrash } from '@fortawesome/pro-light-svg-icons'
import { faArrowTurnDownRight as falArrowTurnDownRight } from '@fortawesome/pro-light-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

PlanningChangeForm.propTypes = {
  survey: surveyProps.isRequired,
  onClose: PropTypes.func.isRequired,
  onHandleShowQuestionModal: PropTypes.func.isRequired,
}

export default function PlanningChangeForm({
  survey,
  onClose,
  onHandleShowQuestionModal,
}) {
  const { t } = useTranslation()
  const { toggleShowConfirmModal, handleChangeId } = useDeleteSurvey()

  const [errors, setErrors] = useState({})
  const [lancement, setLancement] = useState(
    moment(survey.dates.Lancement).format('YYYY-MM-DD'),
  )
  const [cloture, setCloture] = useState(
    moment(survey.dates.Clôture).format('YYYY-MM-DD'),
  )
  const dispatch = useDispatch()
  const surveys = useSelector((state) => surveySelector.selectAll(state))

  const surveyTargets = useSurveyTarget(survey)
  const nbQuestions = survey?.nbQuestions || survey.questionnaire?.nbQuestions

  function handleChangeLancementDate(e) {
    const { duration, unit } = DURATION_BY_TYPE[survey.questionnaire.type]
    const value = e.currentTarget.value
    let newLancementDate
    let newErrors = { ...errors }

    const numberOfWeekday = moment(value).isoWeekday()

    let before = moment(value).isBefore()
    if (survey.questionnaire.type === TypeEnquetes.INDIVIDUELLE)
      before = moment(value).isBefore(moment(), 'days')

    if (before) {
      newErrors = {
        ...newErrors,
        lancement: 'component.planningDate.errors.launch.error_1',
      }
    } else if (
      (numberOfWeekday === 6 || numberOfWeekday === 7) &&
      survey.questionnaire.type !== TypeEnquetes.INDIVIDUELLE
    ) {
      newErrors = {
        ...newErrors,
        lancement: 'component.planningDate.errors.launch.error_2',
      }
    } else {
      newLancementDate = value
      delete newErrors.lancement
    }

    setErrors(newErrors)
    setLancement(newLancementDate)
    setCloture(
      moment(newLancementDate).add(duration, unit).format('YYYY-MM-DD'),
    )
  }

  function handleChangeClotureDate(e) {
    const value = e.currentTarget.value
    let newClotureDate
    let newErrors = { ...errors }

    if (
      moment(lancement).isBefore(moment()) &&
      moment(value).isBefore(moment())
    ) {
      newErrors = {
        ...newErrors,
        cloture: 'component.planningDate.errors.closing.error_1',
      }
    } else if (moment(value).isBefore(moment(lancement).add(1, 'days'))) {
      newErrors = {
        ...newErrors,
        cloture: 'component.planningDate.errors.closing.error_2',
      }
    } else {
      newClotureDate = value
      delete newErrors.cloture
    }

    setErrors(newErrors)
    setCloture(newClotureDate)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const launch = moment(lancement)
    const closing = moment(cloture)

    let diffDays = closing.diff(launch, 'days')
    let dates = updateDatesWithType(
      survey.questionnaire.type,
      launch,
      closing,
      diffDays,
    )

    // la base de sondes cible
    const respondentGroup = surveyTargets.selectedTarget

    if (launch.isValid() && closing.isValid) {
      const isASurvey = surveys.some((s) => s._id === survey._id)
      if (isASurvey) {
        try {
          await backend.surveys.updateSurvey(survey._id, {
            dates: fixRelance(dates),
            respondentGroup: respondentGroup._id,
          })
          notification.success(t('notification.update_survey'), 'lg')
        } catch (error) {
          console.error(error)
          notification.error(t('notification.error'), 'lg')
        }
      } else {
        await dispatch(
          updateOneWaitingSurvey({
            id: survey._id,
            changes: {
              dates: dates,
              isNewSurvey: false,
              isValid: true,
              respondentGroup,
            },
          }),
        )
        notification.warning(t('notification.click_next_to_valid'), 'lg')
      }
      onClose()
    }
  }

  function handleDeleteSurvey() {
    onClose()
    toggleShowConfirmModal(true)
    handleChangeId(survey._id)
  }

  return (
    <>
      <header className='d-flex align-items-center mb-3 fs'>
        <div
          className={`square me-3 bg-${
            mappingSurvey(survey.questionnaire?.type).color
          }`}
        ></div>
        <div className='' style={{ maxWidth: '10rem' }}>
          <p className='mb-1'>{survey.name}</p>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id='questionCard-tooltip' className='position-fixed'>
                {t('click_questionnaire')}
              </Tooltip>
            }
          >
            <p
              className='m-0 fs-sm text-blue-500 cursor-pointer'
              onClick={onHandleShowQuestionModal}
            >
              {t('survey.nb_questions.nb', { count: nbQuestions })}
            </p>
          </OverlayTrigger>
        </div>
      </header>

      <Form className='form fs' onSubmit={(e) => handleSubmit(e)}>
        <Form.Group controlId={'lancement'}>
          <Form.Label className='mb-2'>
            {t('component.planningDate.form.label_launch')} :
          </Form.Label>
          <Form.Control
            className='mb-3'
            type={'date'}
            isInvalid={errors.lancement}
            onChange={(e) => handleChangeLancementDate(e)}
            value={lancement}
            disabled={moment().isAfter(moment(lancement)) && survey.confirmed}
            min={
              survey.questionnaire.type === TypeEnquetes.INDIVIDUELLE
                ? moment().format('YYYY-MM-DD')
                : moment().add(1, 'days').format('YYYY-MM-DD')
            }
          />
          <Form.Control.Feedback type='invalid'>
            {t(errors.lancement)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId={'cloture'}>
          <Form.Label className='mb-2'>
            {t('component.planningDate.form.label_closing')} :
          </Form.Label>
          <Form.Control
            className='mb-3'
            type={'date'}
            isInvalid={errors.cloture}
            onChange={(e) => handleChangeClotureDate(e)}
            value={cloture}
            disabled={moment().isAfter(moment(cloture))}
            min={
              moment(lancement).isBefore(moment())
                ? moment().add(1, 'days').format('YYYY-MM-DD')
                : moment(lancement).add(1, 'days').format('YYYY-MM-DD')
            }
          />
          <Form.Control.Feedback type='invalid'>
            {t(errors.cloture)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId='respondentGroup'>
          <Form.Label className='mb-2'>
            {t('component.planningDate.form.label_respondent')} :
          </Form.Label>

          <DropdownButton
            drop='up-centered'
            disabled={
              surveyTargets.disabled ||
              (moment().isAfter(moment(lancement)) && survey.confirmed)
            }
            title={
              surveyTargets.selectedTarget
                ? surveyTargets.selectedTarget?.name
                : t('component.planningDate.form.choice_respondent')
            }
          >
            <Scrollbar
              style={{
                maxHeight: '20rem',
                paddingRight: '1rem',
                width: '20rem',
              }}
            >
              {surveyTargets.targets.map((target) => (
                <Dropdown.Item
                  key={target._id}
                  eventKey={target._id}
                  onClick={() => surveyTargets.selectTarget(target)}
                  active={surveyTargets.isSelected(target)}
                >
                  <span className={classnames({ 'ml-2': target.parentGroup })}>
                    {target.parentGroup && (
                      <FontAwesomeIcon
                        icon={falArrowTurnDownRight}
                        className='mr-2'
                      />
                    )}
                    {target.name}
                  </span>
                </Dropdown.Item>
              ))}
            </Scrollbar>
          </DropdownButton>
        </Form.Group>

        <div className='mt-4 d-flex justify-content-between mx-0'>
          <ButtonWithIcon
            icon={falTrash}
            variant='delete'
            className='text-danger p-0'
            onClick={handleDeleteSurvey}
            disabled={
              moment(lancement).isBefore(moment()) && !survey.isNewSurvey
            }
          >
            {t('component.planningDate.form.cta1')}
          </ButtonWithIcon>

          <Button
            variant='success'
            type='submit'
            disabled={Object.keys(errors).length !== 0}
          >
            {t('component.planningDate.form.cta2')}
          </Button>
        </div>
      </Form>
    </>
  )
}
