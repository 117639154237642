export const dateFilterMapping = {
  age: {
    2: {
      labels: ['Moins de 45 ans', '45 ans et plus'],
      bins: [0, 45, 100],
    },
    3: {
      labels: ['Moins de 25 ans', '25 à 54 ans', '55 ans et plus'],
      bins: [0, 25, 55, 100],
    },
    4: {
      labels: [
        'Moins de 25 ans',
        '25 à 34 ans',
        '35 à 44 ans',
        '45 ans et plus',
      ],
      bins: [0, 25, 35, 45, 100],
    },
    5: {
      labels: [
        'Moins de 25 ans',
        '25 à 34 ans',
        '35 à 44 ans',
        '45 à 54 ans',
        '55 ans et plus',
      ],
      bins: [0, 25, 35, 45, 55, 100],
    },
    6: {
      labels: [
        'Moins de 25 ans',
        '25 à 34 ans',
        '35 à 39 ans',
        '40 à 44 ans',
        '45 à 54 ans',
        '55 ans et plus',
      ],
      bins: [0, 25, 35, 40, 45, 55, 100],
    },
    7: {
      labels: [
        'Moins de 25 ans',
        '25 à 34 ans',
        '35 à 39 ans',
        '40 à 44 ans',
        '45 à 49 ans',
        '50 à 54 ans',
        '55 ans et plus',
      ],
      bins: [0, 25, 35, 40, 45, 50, 55, 100],
    },
    8: {
      labels: [
        'Moins de 25 ans',
        '25 à 29 ans',
        '30 à 34 ans',
        '35 à 39 ans',
        '40 à 44 ans',
        '45 à 49 ans',
        '50 à 54 ans',
        '55 ans et plus',
      ],
      bins: [0, 25, 30, 35, 40, 45, 50, 55, 100],
    },
  },
  seniority: {
    2: {
      labels: ['Moins de 6 ans', '6 ans et plus'],
      bins: [0, 6, 100],
    },
    3: {
      labels: ['Moins de 6 ans', '6 à 10 ans', 'Plus de 10 ans'],
      bins: [0, 6, 10, 100],
    },
    4: {
      labels: ['Moins de 1 an', '1 à 5 ans', '6 à 10 ans', 'Plus de 10 ans'],
      bins: [0, 1, 5, 10, 100],
    },
    5: {
      labels: [
        'Moins de 1 an',
        '1 à 3 ans',
        '4 à 5 ans',
        '6 à 10 ans',
        'Plus de 10 ans',
      ],
      bins: [0, 1, 3, 5, 10, 100],
    },
    6: {
      labels: [
        'Moins de 1 an',
        '1 à 3 ans',
        '4 à 5 ans',
        '6 à 10 ans',
        '11 à 15 ans',
        'Plus de 15 ans',
      ],
      bins: [0, 1, 3, 5, 10, 15, 100],
    },
    7: {
      labels: [
        'Moins de 1 an',
        '1 à 3 ans',
        '4 à 5 ans',
        '6 à 8 ans',
        '9 à 10 ans',
        '11 à 15 ans',
        'Plus de 15 ans',
      ],
      bins: [0, 1, 3, 5, 8, 10, 15, 100],
    },
  },
}
