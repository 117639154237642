import React, { useEffect, useMemo, useState } from 'react'
import ImportModel from './ImportModel'
import ImportFile from './importFile'
import RHMapping from './RHMapping'
import { ImportReport } from './ImportReportModal'
import PropTypes from 'prop-types'
import ImportStatus from './ImportStatus'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { RHStats } from '../RHStats/RHStatsModal'
import { ButtonWithIcon, notification } from '@olystic/design_system_3'
import classNames from 'classnames'
import backend from '../../../../services/backend'
import { useParams } from 'react-router-dom'
import useRHData from '../../../../hooks/useRHData'
import { useRHBase } from '../../../../hooks/useRHBase'
import CreateGroups from './CreateGroup'
// Icons
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons'

RHImport.propTypes = {
  showImportModelModal: PropTypes.bool.isRequired,
  setImportFileModal: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
}

const date_categories_list = ['ps_age', 'ps_anciennete', 'ps_sortie']
const group_list = [
  'ps_sexe',
  'ps_status',
  'ps_site',
  'ps_manager',
  'ps_anciennete',
  'ps_sortie',
]
const MODEL = 'model'
const FILE = 'file'
const MAPPING = 'mapping'
const STATUS = 'status'
const REPORT = 'report'
const STATS = 'stats'
const GROUP = 'group'

function RHImport({
  showImportModelModal,
  setImportFileModal,
  onReload,
  baseId,
}) {
  const { t } = useTranslation()
  const { id } = useParams()
  const base = useRHBase(id)
  const { respondents } = useRHData(id)

  const [currentStep, setCurrentStep] = useState(MODEL)
  const [canGo, setCanGo] = useState(false)
  const [rhFile, setRHFile] = useState(null)
  const [PreparseResponse, setPreparseResponse] = useState(null)
  const [report, setReport] = useState(null)
  const [jobStatus, setJobStatus] = useState()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [mapping, setMapping] = useState({})
  const [columns, setColumns] = useState([])
  const [options, setOptions] = useState([])
  const [groups, setGroups] = useState({})

  const processedData = useMemo(
    () => respondents.data?.processedData || [],
    [respondents.data?.processedData],
  )

  useEffect(() => {
    const groups = {}

    for (let respondent of processedData) {
      for (let [key, value] of Object.entries(respondent.rhData)) {
        if (group_list.includes(key)) {
          if (!groups[key]) {
            groups[key] = new Set()
          }

          if (date_categories_list.includes(key) && value.age !== undefined) {
            groups[key].add(value.group)
          } else if (typeof value === 'string') {
            groups[key].add(value)
          }
        }
      }
    }

    const groupedObject = Object.keys(groups).reduce((acc, key) => {
      if (groups[key].size > 0) {
        acc[key] = Array.from(groups[key]).reduce((innerAcc, groupValue) => {
          innerAcc[groupValue] = false
          return innerAcc
        }, {})
      }
      return acc
    }, {})
    setGroups(groupedObject)
  }, [processedData])

  useEffect(() => {
    if (PreparseResponse) {
      setColumns(Object.entries(PreparseResponse.data.columns))
      setOptions(PreparseResponse.data.options)

      const autoMapping = Object.assign(
        {},
        ...PreparseResponse.data.options
          .filter((opt) => !!PreparseResponse.data.columns[opt.label])
          .map((opt) => ({ [opt.label]: opt })),
      )
      setMapping(autoMapping)
    }
  }, [PreparseResponse])

  const nextStep = () => {
    const steps = Object.keys(STEPS)
    switch (currentStep) {
      case MAPPING:
        handleValidate()
        break
      case REPORT:
        finishImport()
        break
      case GROUP:
        handleSubmit(groups)
        break
      default:
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) + 1])
    }
  }

  const previousStep = () => {
    const steps = Object.keys(STEPS)
    switch (currentStep) {
      case FILE:
      case MAPPING:
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) - 1])
        setCanGo(false)
        break
      case REPORT:
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) - 2])
        break
      default:
        setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) - 1])
    }
  }

  const closeModal = () => {
    setCurrentStep(MODEL)
    setImportFileModal(false)
  }

  const handleFirstParsing = (acceptedFiles) => {
    const form = new FormData()
    form.set('file', acceptedFiles[0])
    backend.parseRH
      .preparse(form)
      .then((response) => {
        setCanGo(true)
        setPreparseResponse(response)
        setRHFile(acceptedFiles[0])
      })
      .catch((uploadError) => {
        console.log(uploadError)
      })
  }

  const finishRHMapping = (jobStatus) => {
    const steps = Object.keys(STEPS)
    setJobStatus(jobStatus)
    setReport(null)
    setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) + 1])
  }

  const handleFilters = (column, option) => {
    let newMapping = { ...mapping }
    if (option.value === 'none') {
      delete newMapping[column]
    } else {
      newMapping[column] = option
    }
    setMapping(newMapping)
  }

  const handleValidate = () => {
    if (!mapping) return setShowErrorMessage(true)
    const variables = Object.values(mapping).map((f) => f.value)
    if (variables.includes('ps_email') && variables.includes('ps_matricule')) {
      const form = new FormData()
      form.append('file', rhFile)
      form.set('filters', JSON.stringify(mapping))

      backend.parseRH
        .parse(baseId, form)
        .then((res) => finishRHMapping(res.data))
    } else {
      setShowErrorMessage(true)
    }
  }

  const finishStatus = (newReport) => {
    if (!report) {
      setReport(newReport)
      nextStep()
    }
  }

  const finishImport = () => {
    const steps = Object.keys(STEPS)
    setJobStatus(null)
    setRHFile(null)
    onReload()
    if (currentStep === steps[steps.length - 1]) {
      closeModal()
    } else {
      setCurrentStep((currentStep) => steps[steps.indexOf(currentStep) + 1])
    }
  }

  const handleSubmit = async (values) => {
    try {
      for (let key of Object.keys(values)) {
        for (let group of Object.keys(values[key])) {
          if (values[key][group]) {
            const respondents = processedData
              .filter((respondent) => {
                if (date_categories_list.includes(key)) {
                  return respondent.rhData[key].group === group
                }
                return respondent.rhData[key] === group
              })
              .map((respondent) => respondent._id)

            base.createGroup.mutate({
              name: `${t(`category.${key}`)} - ${group}`,
              respondents,
            })
          }
        }
      }
      notification.success(t('notification.import'), 'lg')
    } catch (e) {
      notification.error(t('notification.error'), 'lg')
    }
    closeModal()
  }

  let STEPS = {
    [MODEL]: <ImportModel goNextStep={nextStep} />,
    [FILE]: <ImportFile onFirstParsing={handleFirstParsing} />,
    [MAPPING]: (
      <RHMapping
        showErrorMessage={showErrorMessage}
        onShowErrorMessage={setShowErrorMessage}
        columns={columns}
        options={options}
        mapping={mapping}
        onFilter={handleFilters}
      />
    ),
    [STATUS]: (
      <ImportStatus
        status={jobStatus}
        setStatus={setJobStatus}
        goPrevStep={previousStep}
        goNextStep={finishStatus}
      />
    ),
    [REPORT]: <ImportReport data={report} />,
  }

  if (!base.isSurvey) {
    STEPS = {
      ...STEPS,
      [STATS]: <RHStats baseId={base.id} />,
      [GROUP]: (
        <CreateGroups
          groups={groups}
          onChangeGroups={setGroups}
          baseId={base.id}
        />
      ),
    }
  }

  return (
    <Modal
      size={currentStep === MAPPING || currentStep === STATS ? 'xl' : 'lg'}
      centered
      show={showImportModelModal}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('component.rhImport.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={currentStep === STATS && 'p-0'}>
        {STEPS[currentStep]}
      </Modal.Body>
      <Modal.Footer className='justify-content-between pt-4'>
        <div>
          <ButtonWithIcon
            icon={farChevronLeft}
            variant='secondary'
            className={classNames({
              'd-none': currentStep === MODEL || currentStep === STATUS,
            })}
            onClick={() => previousStep()}
          >
            {t('component.rhImport.cta1')}
          </ButtonWithIcon>
        </div>

        <div className={`d-flex gap-3 ${currentStep === 0 ? 'me-0' : ' me-5'}`}>
          {Object.keys(STEPS).map((step) => (
            <div
              key={step}
              className={`p-1 rounded-circle ${
                step === currentStep ? 'bg-primary' : 'bg-dark'
              }`}
            />
          ))}
        </div>
        <div>
          <Button
            onClick={() => nextStep()}
            disabled={!canGo}
            className={classNames({
              'd-none': currentStep === MODEL || currentStep === STATUS,
            })}
          >
            {currentStep === Object.keys(STEPS)[Object.keys(STEPS).length - 1]
              ? t('component.rhImport.cta3')
              : t('component.rhImport.cta2')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default RHImport
