import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from './Dropzone'
import { useTranslation, Trans } from 'react-i18next'

export default function ImportFile({ onFirstParsing }) {
  const { t } = useTranslation()

  return (
    <>
      <h4>{t('component.importFile.summary')}</h4>
      <p className='pb-3 text-black-50'>
        <Trans i18nKey='component.importFile.description'>
          Lorsque votre fichier est complété, chargez-le ici pour que vos
          données soient intégrées à la plateforme.
          <br />
          Vous pourrez ensuite vérifier votre import grâce au rapport
          d’importation et confirmer le mapping de vos variables.
        </Trans>
      </p>
      <Dropzone onDrop={onFirstParsing} />
    </>
  )
}

ImportFile.propTypes = {
  onFirstParsing: PropTypes.func,
}
