import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { deepCopy } from '../../../../lib/functions'
import { TextInputFormik } from '@olystic/design_system_3'
import { isAfter } from 'date-fns'

export const defaultRespondent = {
  email: '',
  matricule: '',
  rhData: {
    ps_age: '',
    ps_agence: '',
    ps_anciennete: '',
    ps_bu: '',
    ps_classification: '',
    ps_classification_niv_1: '',
    ps_classification_niv_2: '',
    ps_contrat: '',
    ps_departement: '',
    ps_direction: '',
    ps_division: '',
    ps_emploi: '',
    ps_entreprise: '',
    ps_etablissement: '',
    ps_famille_metiers: '',
    ps_genre: '',
    ps_manager: '',
    ps_metier: '',
    ps_niveau_managerial: '',
    ps_nom: '',
    ps_pays: '',
    ps_perimetre: '',
    ps_plaque: '',
    ps_prenom: '',
    ps_region: '',
    ps_service: '',
    ps_sexe: '',
    ps_site: '',
    ps_societe: '',
    ps_status: '',
    ps_sortie: '',
  },
}

export const RespondentSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('rh.respondent_form.email.error.required'),
  matricule: yup
    .string()
    .trim()
    .min(1, 'rh.respondent_form.matricule.error.min')
    .required('rh.respondent_form.matricule.error.required'),
  rhData: yup.object().shape({
    ps_nom: yup.string(),
    ps_prenom: yup.string(),
    ps_age: yup.date(),
    ps_anciennete: yup.date(),
    ps_direction: yup.string(),
    ps_status: yup.string(),
    ps_manager: yup.string(),
    ps_site: yup.string(),
    ps_service: yup.string(),
    ps_pays: yup.string(),
    ps_metier: yup.string(),
    ps_agence: yup.string(),
    ps_bu: yup.string(),
    ps_classification: yup.string(),
    ps_classification_niv_1: yup.string(),
    ps_classification_niv_2: yup.string(),
    ps_contrat: yup.string(),
    ps_departement: yup.string(),
    ps_division: yup.string(),
    ps_emploi: yup.string(),
    ps_entreprise: yup.string(),
    ps_etablissement: yup.string(),
    ps_famille_metiers: yup.string(),
    ps_genre: yup.string(),
    ps_niveau_managerial: yup.string(),
    ps_perimetre: yup.string(),
    ps_plaque: yup.string(),
    ps_region: yup.string(),
    ps_sexe: yup.string(),
    ps_societe: yup.string(),
    ps_sortie: yup.date(),
  }),
})

function formDateFormat(dateString) {
  let toConvert = dateString
  if (typeof toConvert === 'object') toConvert = toConvert?.date
  if (toConvert) {
    const date = moment(toConvert)
    if (date.isValid()) return date.format('YYYY-MM-DD')
  }
  return ''
}

export function toFormFormat(respondent) {
  if (!respondent) return deepCopy(defaultRespondent)
  // TODO unit test
  const formatted = {
    email: respondent.email || '',
    matricule: respondent.matricule || '',
  }
  if (respondent._id) formatted._id = respondent._id
  formatted.rhData = Object.assign(
    { ...defaultRespondent.rhData },
    respondent.rhData
  )
  formatted.rhData.ps_age = formDateFormat(formatted.rhData.ps_age)
  formatted.rhData.ps_anciennete = formDateFormat(
    formatted.rhData.ps_anciennete
  )
  formatted.rhData.ps_sortie = formDateFormat(formatted.rhData.ps_sortie)

  return formatted
}

export function toBackendFormat(values) {
  const formatted = deepCopy(values)
  formatted.email = formatted.email.trim()
  formatted.matricule = formatted.matricule.trim()
  for (const prop of Object.keys(formatted.rhData)) {
    if (!formatted.rhData[prop]?.trim()) delete formatted.rhData[prop]
  }
  if (formatted.rhData.ps_manager)
    formatted.rhData.ps_manager = formatted.rhData.ps_manager === 'Manager'

  return formatted
}

function RespondentForm({ respondent, onCreate, onUpdate, edit, submitText }) {
  const { t } = useTranslation()
  const respondentData = useMemo(() => toFormFormat(respondent), [respondent])

  return (
    <Formik
      initialValues={respondentData}
      validationSchema={RespondentSchema}
      onSubmit={async (values, actions) => {
        if (edit) await onUpdate(toBackendFormat(values), actions)
        else await onCreate(toBackendFormat(values), actions)
      }}
    >
      {(formik) => (
        <Form noValidate>
          <Row>
            <Col>
              <fieldset>
                <legend>
                  {t('rh.respondent_form.legend.individual_data')}
                </legend>
                <Form.Group controlId="matricule">
                  <Form.Label>
                    {t('category.ps_matricule')}
                    {!edit && <span className="text-danger">*</span>}
                  </Form.Label>
                  <Form.Control
                    name="matricule"
                    value={formik.values.matricule}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={
                      formik.touched.matricule && !formik.errors.matricule
                    }
                    isInvalid={
                      formik.touched.matricule && formik.errors.matricule
                    }
                    placeholder={t('rh.respondent_form.matricule.placeholder')}
                    disabled={edit}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(formik.errors.matricule)}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback />
                </Form.Group>

                <Row className="my-3">
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_nom"
                      label={t('category.ps_nom')}
                      placeholder={t('rh.respondent_form.nom.placeholder')}
                    />
                  </Col>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_prenom"
                      label={t('category.ps_prenom')}
                      placeholder={t('rh.respondent_form.prenom.placeholder')}
                    />
                  </Col>
                </Row>

                <Form.Group controlId="email" className="my-3">
                  <Form.Label>
                    {t('category.ps_email')}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.email && !formik.errors.email}
                    isInvalid={formik.touched.email && formik.errors.email}
                    type="email"
                    placeholder={t('rh.respondent_form.email.placeholder')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(formik.errors.email)}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback />
                </Form.Group>

                <Form.Group controlId="rhData.ps_age" className="my-3">
                  <Form.Label>{t('category.ps_age')}</Form.Label>
                  <Form.Control
                    name="rhData.ps_age"
                    value={formik.values.rhData.ps_age}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="date"
                    isInvalid={isAfter(
                      new Date(formik.values.rhData.ps_age),
                      new Date()
                    )}
                  />
                </Form.Group>

                <Row>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_sexe"
                      label={t('category.ps_sexe')}
                      placeholder={t('rh.respondent_form.sexe.placeholder')}
                    />
                  </Col>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_genre"
                      label={t('category.ps_genre')}
                      placeholder={t('rh.respondent_form.genre.placeholder')}
                    />
                  </Col>
                </Row>
              </fieldset>
            </Col>
            <Col>
              <fieldset>
                <legend>{t('rh.respondent_form.legend.job_data')}</legend>

                <TextInputFormik
                  name="rhData.ps_status"
                  label={t('category.ps_statut')}
                  placeholder={t('rh.respondent_form.statut.placeholder')}
                />

                <Row className="my-3">
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_emploi"
                      label={t('category.ps_emploi')}
                      placeholder={t('rh.respondent_form.emploi.placeholder')}
                    />
                  </Col>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_contrat"
                      label={t('category.ps_contrat')}
                      placeholder={t('rh.respondent_form.contrat.placeholder')}
                    />
                  </Col>
                </Row>

                <TextInputFormik
                  name="rhData.ps_classification"
                  label={t('category.ps_classification')}
                  placeholder={t(
                    'rh.respondent_form.classification.placeholder'
                  )}
                />

                <Row className="my-3">
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_classification_niv_1"
                      label={t('category.ps_classification_niv_1')}
                      placeholder={t(
                        'rh.respondent_form.classification_niv_1.placeholder'
                      )}
                    />
                  </Col>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_classification_niv_2"
                      label={t('category.ps_classification_niv_2')}
                      placeholder={t(
                        'rh.respondent_form.classification_niv_2.placeholder'
                      )}
                    />
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_famille_metiers"
                      label={t('category.ps_famille_metiers')}
                      placeholder={t(
                        'rh.respondent_form.famille_metiers.placeholder'
                      )}
                    />
                  </Col>
                  <Col>
                    <Form.Group controlId="rhData.ps_metier">
                      <Form.Label>{t('category.ps_metier')}</Form.Label>
                      <Form.Control
                        name="rhData.ps_metier"
                        value={formik.values.rhData.ps_metier}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        placeholder={t('rh.respondent_form.metier.placeholder')}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_niveau_managerial"
                      label={t('category.ps_niveau_managerial')}
                      placeholder={t(
                        'rh.respondent_form.niveau_managerial.placeholder'
                      )}
                    />
                  </Col>

                  <Col>
                    <Form.Label>{t('category.ps_manager')}</Form.Label>

                    <Form.Select
                      id="rhData.ps_manager"
                      value={formik.values.rhData.ps_manager}
                      onChange={formik.handleChange}
                    >
                      <option value="">
                        {t('rh.respondent_form.manager.option1')}
                      </option>
                      <option value="Manager">
                        {t('rh.respondent_form.manager.option2')}
                      </option>
                      <option value="Non manager">
                        {t('rh.respondent_form.manager.option3')}
                      </option>
                    </Form.Select>
                  </Col>
                </Row>
              </fieldset>
            </Col>
            <Col>
              <fieldset>
                <legend>
                  {t('rh.respondent_form.legend.organization_data')}
                </legend>

                <TextInputFormik
                  name="rhData.ps_societe"
                  label={t('category.ps_societe')}
                  placeholder={t('rh.respondent_form.societe.placeholder')}
                />

                <Row className="my-3">
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_entreprise"
                      label={t('category.ps_entreprise')}
                      placeholder={t(
                        'rh.respondent_form.entreprise.placeholder'
                      )}
                    />
                  </Col>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_bu"
                      label={t('category.ps_bu')}
                      placeholder={t('rh.respondent_form.bu.placeholder')}
                    />
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_division"
                      label={t('category.ps_division')}
                      placeholder={t('rh.respondent_form.division.placeholder')}
                    />
                  </Col>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_direction"
                      label={t('category.ps_direction')}
                      placeholder={t(
                        'rh.respondent_form.direction.placeholder'
                      )}
                    />
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_departement"
                      label={t('category.ps_departement')}
                      placeholder={t(
                        'rh.respondent_form.departement.placeholder'
                      )}
                    />
                  </Col>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_perimetre"
                      label={t('category.ps_perimetre')}
                      placeholder={t(
                        'rh.respondent_form.perimetre.placeholder'
                      )}
                    />
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_etablissement"
                      label={t('category.ps_etablissement')}
                      placeholder={t(
                        'rh.respondent_form.etablissement.placeholder'
                      )}
                    />
                  </Col>
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_agence"
                      label={t('category.ps_agence')}
                      placeholder={t('rh.respondent_form.agence.placeholder')}
                    />
                  </Col>
                </Row>

                <Row>
                  <Form.Group as={Col} controlId="rhData.ps_service">
                    <Form.Label>{t('category.ps_service')}</Form.Label>
                    <Form.Control
                      name="rhData.ps_service"
                      value={formik.values.rhData.ps_service}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder={t('rh.respondent_form.service.placeholder')}
                    />
                  </Form.Group>
                </Row>
              </fieldset>
            </Col>
          </Row>

          <Row>
            <Col xs={4}>
              <fieldset className="mt-3 auto">
                <legend>{t('rh.respondent_form.legend.in_out')}</legend>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="rhData.ps_anciennete">
                    <Form.Label>{t('category.ps_anciennete')}</Form.Label>
                    <Form.Control
                      name="rhData.ps_anciennete"
                      value={formik.values.rhData.ps_anciennete}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="date"
                      isInvalid={isAfter(
                        new Date(formik.values.rhData.ps_anciennete),
                        new Date()
                      )}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="rhData.ps_sortie">
                    <Form.Label>{t('category.ps_sortie')}</Form.Label>
                    <Form.Control
                      name="rhData.ps_sortie"
                      value={formik.values.rhData.ps_sortie}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="date"
                    />
                  </Form.Group>
                </Row>
              </fieldset>
            </Col>

            <Col xs={8}>
              <fieldset className="mt-3 auto">
                <legend>
                  {t('rh.respondent_form.legend.geographic_data')}
                </legend>

                <Row className="mb-3">
                  <Col>
                    <TextInputFormik
                      name="rhData.ps_plaque"
                      label={t('category.ps_plaque')}
                      placeholder={t('rh.respondent_form.plaque.placeholder')}
                    />
                  </Col>

                  <Form.Group as={Col} controlId="rhData.ps_pays">
                    <Form.Label>{t('category.ps_pays')}</Form.Label>
                    <Form.Control
                      name="rhData.ps_pays"
                      value={formik.values.rhData.ps_pays}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder={t('rh.respondent_form.pays.placeholder')}
                    />
                  </Form.Group>

                  <Col>
                    <TextInputFormik
                      name="rhData.ps_region"
                      label={t('category.ps_region')}
                      placeholder={t('rh.respondent_form.region.placeholder')}
                    />
                  </Col>

                  <Form.Group as={Col} controlId="rhData.ps_site">
                    <Form.Label>{t('category.ps_site')}</Form.Label>
                    <Form.Control
                      name="rhData.ps_site"
                      value={formik.values.rhData.ps_site}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      placeholder={t('rh.respondent_form.site.placeholder')}
                    />
                  </Form.Group>
                </Row>
              </fieldset>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <Button
                className="float-end"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                {submitText}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

RespondentForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  submitText: PropTypes.string.isRequired,
  respondent: PropTypes.shape({
    email: PropTypes.string,
    matricule: PropTypes.string,
    rhData: PropTypes.shape({
      ps_nom: PropTypes.string,
      ps_prenom: PropTypes.string,
      ps_age: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ date: PropTypes.string }),
      ]),
      ps_anciennete: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ date: PropTypes.string }),
      ]),
      ps_direction: PropTypes.string,
      ps_status: PropTypes.string,
      ps_manager: PropTypes.string,
      ps_site: PropTypes.string,
      ps_service: PropTypes.string,
      ps_pays: PropTypes.string,
      ps_metier: PropTypes.string,
      ps_agence: PropTypes.string,
      ps_bu: PropTypes.string,
      ps_classification: PropTypes.string,
      ps_classification_niv_1: PropTypes.string,
      ps_classification_niv_2: PropTypes.string,
      ps_contrat: PropTypes.string,
      ps_departement: PropTypes.string,
      ps_division: PropTypes.string,
      ps_emploi: PropTypes.string,
      ps_entreprise: PropTypes.string,
      ps_etablissement: PropTypes.string,
      ps_famille_metiers: PropTypes.string,
      ps_genre: PropTypes.string,
      ps_niveau_managerial: PropTypes.string,
      ps_perimetre: PropTypes.string,
      ps_plaque: PropTypes.string,
      ps_region: PropTypes.string,
      ps_sexe: PropTypes.string,
      ps_societe: PropTypes.string,
    }),
  }),
}

RespondentForm.defaultProps = {
  respondent: defaultRespondent,
}

export default RespondentForm
