import * as XLSX from "xlsx-js-style"

export default function exportExcel(data, formatSheet) {

  let wb = XLSX.utils.book_new()
  let ws = XLSX.utils.json_to_sheet(data, {
    dateNF: "dd/MM/yyyy",
    cellDates: true,
  })

  const range = XLSX.utils.decode_range(ws["!ref"] || "A1")
  ws["!rows"] = Array.from({ length: range.e.r + 1 }, () => ({ hpt: 23 }))
  ws["!cols"] = fitToColumn(data)

  ws = formatSheet(ws)

  XLSX.utils.book_append_sheet(wb, ws, "table")

  XLSX.writeFile(wb, "table.xlsx")
}

export function fitToColumn(data) {
  return Object.keys(data[0]).map((key) => {
    let width = data.reduce((prev, curr) => Math.max(prev, String(curr[key]).length), 10)

    if (width < 10) width = 10
    if (width > 150) width = 70
    return { wch: width + 5 }
  })
}

export const defaultSheetFormat = formatCell => ws => {
  for (let i in ws) {
    if (typeof ws[i] != "object") continue
    let cell = XLSX.utils.decode_cell(i)

    ws[i].s = {
      // styling for all cells
      font: {
        name: "Carlito",
      },
      alignment: {
        vertical: "center",
      },
    }
    // styling header
    if (cell.r === 0) {
      ws[i].s = {
        font: {
          sz: 14,
          bold: true,
          name: "Carlito",
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          bottom: {
            style: "thin",
            color: "000000",
          },
        },
      }
    }
    else if(formatCell)
      formatCell(cell, ws, i)
  }

  return ws
}
