import React, { useCallback } from 'react'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import SelectFilter from '../features/RH/components/RHTable/components/SelectFilter'
import { useTranslation } from 'react-i18next'
import { defaultFilterOptions } from '../features/RH/components/RHTable/rhTransform'
import Date from '../components/render/Date'
import SuiviToggle from '../features/RH/components/RespondentsList/SuiviToggle'
import { Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useRHBase } from './useRHBase'

const date_categories_list = ['ps_age', 'ps_anciennete', 'ps_sortie']

export function useRHTable(respondents, withSuivi = false) {
  const { t } = useTranslation()
  const { id } = useParams()
  const base = useRHBase(id)

  const baseCategory = base.base?.data?.category

  const pageSizeOptions = [10, 20, 30, 40, 50, 100]

  const processedData = React.useMemo(
    () => respondents?.processedData || [],
    [respondents.processedData],
  )
  const filterOptions = React.useMemo(
    () => respondents?.filterOptions || defaultFilterOptions,
    [respondents.filterOptions],
  )

  const filterTypes = React.useMemo(
    () => ({
      multiple: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id] || t('common.undefined')
          if (filterValue) {
            return !filterValue.has(rowValue)
          }
          return true
        })
      },
      group: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]?.group || t('common.undefined')
          if (filterValue) {
            return !filterValue.has(rowValue)
          }
          return true
        })
      },
    }),

    [t],
  )

  const categoriesFilter = React.useMemo(() => {
    const categories = new Set()
    categories.add('ps_matricule')
    categories.add('ps_email')

    for (let respondent of processedData) {
      for (let [key, value] of Object.entries(respondent.rhData)) {
        if (date_categories_list.includes(key)) {
          value.age !== undefined && categories.add(key)
        } else {
          if (value) categories.add(key)
        }
      }
    }
    return [...categories]
  }, [processedData])

  const columns = React.useMemo(() => {
    const col = [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <Form.Check {...getToggleAllRowsSelectedProps()} className='py-3' />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <Form.Check {...row.getToggleRowSelectedProps()} className='py-3' />
          </div>
        ),
      },
    ]

    const allColumns = {
      ps_matricule: {
        Header: t('category.ps_matricule'),
        accessor: 'matricule',
        disableFilters: true,
      },
      ps_nom: {
        Header: t('category.ps_nom'),
        accessor: 'rhData.ps_nom',
        disableFilters: true,
        toggleVisibility: true,
      },
      ps_prenom: {
        Header: t('category.ps_prenom'),
        accessor: 'rhData.ps_prenom',
        disableFilters: true,
        toggleVisibility: true,
      },

      ps_email: {
        Header: t('category.ps_email'),
        accessor: 'email',
        disableFilters: true,
      },
      ps_status: {
        Header: t('category.ps_status'),
        accessor: 'rhData.ps_status',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_status'],
        toggleVisibility: true,
      },
      ps_direction: {
        Header: t('category.ps_direction'),
        accessor: 'rhData.ps_direction',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_direction'],
        toggleVisibility: true,
      },
      ps_age: {
        Header: t('category.ps_age'),
        accessor: 'rhData.ps_age',
        Cell: ({ cell: { value } }) => {
          if (value.age != null)
            return (
              <span className={`${value.age <= 0 ? 'text-danger' : ''}`}>
                {t('rh.age', { count: value.age })}
              </span>
            )
          return ''
        },
        Filter: SelectFilter,
        filter: 'group',
        filterOptions: filterOptions['rhData.ps_age'],
        toggleVisibility: true,
      },
      ps_manager: {
        Header: t('category.ps_manager'),
        accessor: 'rhData.ps_manager',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_manager'],
        toggleVisibility: true,
      },
      ps_anciennete: {
        Header: t('category.ps_anciennete'),
        accessor: 'rhData.ps_anciennete',
        Cell: ({ cell: { value } }) => {
          if (value.age != null)
            return (
              <span className={`${value.age < 0 ? 'text-danger' : ''}`}>
                {t('rh.age', { count: value.age })}
              </span>
            )
          return ''
        },
        Filter: SelectFilter,
        filter: 'group',
        filterOptions: filterOptions['rhData.ps_anciennete'],
        toggleVisibility: true,
      },
      ps_sortie: {
        Header: t('category.ps_sortie'),
        accessor: 'rhData.ps_sortie',
        Cell: ({ cell: { value } }) => {
          if (value.date) return <Date>{value.date}</Date>
          return ''
        },
        Filter: SelectFilter,
        filter: 'group',
        filterOptions: filterOptions['rhData.ps_sortie'],
        toggleVisibility: true,
      },
      ps_metier: {
        Header: t('category.ps_metier'),
        accessor: 'rhData.ps_metier',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_metier'],
        toggleVisibility: true,
      },
      ps_service: {
        Header: t('category.ps_service'),
        accessor: 'rhData.ps_service',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_service'],
        toggleVisibility: true,
      },
      ps_site: {
        Header: t('category.ps_site'),
        accessor: 'rhData.ps_site',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_site'],
        toggleVisibility: true,
      },
      ps_pays: {
        Header: t('category.ps_pays'),
        accessor: 'rhData.ps_pays',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_pays'],
        toggleVisibility: true,
      },
      ps_agence: {
        Header: t('category.ps_agence'),
        accessor: 'rhData.ps_agence',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_agence'],
        toggleVisibility: true,
      },
      ps_bu: {
        Header: t('category.ps_bu'),
        accessor: 'rhData.ps_bu',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_bu'],
        toggleVisibility: true,
      },
      ps_classification: {
        Header: t('category.ps_classification'),
        accessor: 'rhData.ps_classification',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_classification'],
        toggleVisibility: true,
      },
      ps_classification_niv_1: {
        Header: t('category.ps_classification_niv_1'),
        accessor: 'rhData.ps_classification_niv_1',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_classification_niv_1'],
        toggleVisibility: true,
      },
      ps_classification_niv_2: {
        Header: t('category.ps_classification_niv_2'),
        accessor: 'rhData.ps_classification_niv_2',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_classification_niv_2'],
        toggleVisibility: true,
      },
      ps_contrat: {
        Header: t('category.ps_contrat'),
        accessor: 'rhData.ps_contrat',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_contrat'],
        toggleVisibility: true,
      },
      ps_departement: {
        Header: t('category.ps_departement'),
        accessor: 'rhData.ps_departement',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_departement'],
        toggleVisibility: true,
      },
      ps_division: {
        Header: t('category.ps_division'),
        accessor: 'rhData.ps_division',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_division'],
        toggleVisibility: true,
      },
      ps_emploi: {
        Header: t('category.ps_emploi'),
        accessor: 'rhData.ps_emploi',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_emploi'],
        toggleVisibility: true,
      },
      ps_entreprise: {
        Header: t('category.ps_entreprise'),
        accessor: 'rhData.ps_entreprise',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_entreprise'],
        toggleVisibility: true,
      },
      ps_etablissement: {
        Header: t('category.ps_etablissement'),
        accessor: 'rhData.ps_etablissement',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_etablissement'],
        toggleVisibility: true,
      },
      ps_famille_metiers: {
        Header: t('category.ps_famille_metiers'),
        accessor: 'rhData.ps_famille_metiers',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_famille_metiers'],
        toggleVisibility: true,
      },
      ps_genre: {
        Header: t('category.ps_genre'),
        accessor: 'rhData.ps_genre',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_genre'],
        toggleVisibility: true,
      },
      ps_niveau_managerial: {
        Header: t('category.ps_niveau_managerial'),
        accessor: 'rhData.ps_niveau_managerial',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_niveau_managerial'],
        toggleVisibility: true,
      },
      ps_perimetre: {
        Header: t('category.ps_perimetre'),
        accessor: 'rhData.ps_perimetre',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_perimetre'],
        toggleVisibility: true,
      },
      ps_plaque: {
        Header: t('category.ps_plaque'),
        accessor: 'rhData.ps_plaque',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_plaque'],
        toggleVisibility: true,
      },
      ps_region: {
        Header: t('category.ps_region'),
        accessor: 'rhData.ps_region',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_region'],
        toggleVisibility: true,
      },
      ps_sexe: {
        Header: t('category.ps_sexe'),
        accessor: 'rhData.ps_sexe',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_sexe'],
        toggleVisibility: true,
      },
      ps_societe: {
        Header: t('category.ps_societe'),
        accessor: 'rhData.ps_societe',
        Filter: SelectFilter,
        filter: 'multiple',
        filterOptions: filterOptions['rhData.ps_societe'],
        toggleVisibility: true,
      },
    }

    for (let categorie of categoriesFilter) {
      if (categorie === baseCategory) {
        col.splice(5, 0, allColumns[categorie])
      } else {
        col.push(allColumns[categorie])
      }
    }

    if (withSuivi) {
      col.splice(1, 0, {
        Header: t('Suivi'),
        accessor: 'suivi',
        disableFilters: true,
        Cell: ({ row }) => <SuiviToggle respondentId={row.original._id} />,
      })
    }
    return col
  }, [t, filterOptions, withSuivi, categoriesFilter, baseCategory])

  const hiddenColumns = [
    'rhData.ps_age',
    'rhData.ps_site',
    'rhData.ps_service',
    'rhData.ps_sortie',
    'rhData.ps_pays',
    'rhData.ps_agence',
    'rhData.ps_bu',
    'rhData.ps_classification',
    'rhData.ps_classification_niv_1',
    'rhData.ps_classification_niv_2',
    'rhData.ps_contrat',
    'rhData.ps_departement',
    'rhData.ps_division',
    'rhData.ps_emploi',
    'rhData.ps_entreprise',
    'rhData.ps_etablissement',
    'rhData.ps_famille_metiers',
    'rhData.ps_genre',
    'rhData.ps_niveau_managerial',
    'rhData.ps_perimetre',
    'rhData.ps_plaque',
    'rhData.ps_region',
    'rhData.ps_sexe',
    'rhData.ps_societe',
  ]

  const globalFilter = useCallback((rows, ids, query) => {
    const lowerCaseQuery = query.toLowerCase()

    return rows.filter((row) => {
      return ids.some((id) => {
        let value = row.values[id]

        if (
          id === 'rhData.ps_age' ||
          id === 'rhData.ps_anciennete' ||
          id === 'rhData.ps_sortie'
        ) {
          value = row.values[id]?.group
        }

        if (value) {
          const stringValue = String(value).toLowerCase()
          return stringValue.includes(lowerCaseQuery)
        }

        return false
      })
    })
  }, [])

  const instance = useTable(
    {
      columns,
      data: processedData,
      filterTypes,
      initialState: {
        pageSize: pageSizeOptions[0],
        options: pageSizeOptions,
        hiddenColumns: hiddenColumns.filter(
          (col) => col.split('rhData.')[1] !== baseCategory,
        ),
      },
      globalFilter,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  )

  return instance
}
