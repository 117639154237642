import React from 'react'
import style from './QuestionModal.module.scss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { mappingSurvey } from '@olystic/common/survey.mjs'
import {
  BLOC_UNIQUE,
  CHARACTER,
  CHARACTER_USER,
  MULTIPLE,
  MULTIPLE_USER,
  NUMERIC,
  NUMERIC_USER,
  SCALE,
  SCALE_USER,
  UNIQUE,
  UNIQUE_USER,
  BLOC_MULTIPLE,
  NPS,
} from '@olystic/common/question.mjs'
// Components
import { Button, Modal } from 'react-bootstrap'
import { InfoButton, Scrollbar } from '@olystic/design_system_3'
// Translation
import { useTranslation } from 'react-i18next'

QuestionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  questionnaire: PropTypes.objectOf(PropTypes.any).isRequired,
  canView: PropTypes.bool,
}

QuestionModal.defaultProps = {
  canView: true,
}

export default function QuestionModal({
  show,
  onHide,
  questionnaire,
  canEdit,
  canView,
}) {
  const { t } = useTranslation()
  const { type, label, nbQuestions, sections } = questionnaire
  const [allQuestionsId, setAllQuestionsId] = React.useState([])
  const [collapsedAnswerId, setCollapsedAnswerId] = React.useState([])

  React.useEffect(() => {
    const all = []
    questionnaire.sections.forEach((section) => {
      all.push(section.questions.map((question) => question._id))
    })
    setAllQuestionsId(all.flat())
  }, [questionnaire])

  const getAnswerToggler = (id) => {
    const collapsed = collapsedAnswerId.includes(id)

    setCollapsedAnswerId(
      collapsed
        ? collapsedAnswerId.filter((answerId) => answerId !== id)
        : [...collapsedAnswerId, id],
    )
  }

  const handleCollapsedAnswersId = () => {
    collapsedAnswerId.length === allQuestionsId.length
      ? setCollapsedAnswerId([])
      : setCollapsedAnswerId(allQuestionsId)
  }

  const formatAnswer = (question) => {
    let str = ''

    switch (question.type) {
      case NPS:
        str = t('component.questionModal.types.nps')
        break
      case SCALE:
      case SCALE_USER:
        str = t('component.questionModal.types.scale')
        break
      case CHARACTER:
      case CHARACTER_USER:
        str = t('component.questionModal.types.open')
        break
      case UNIQUE:
      case UNIQUE_USER:
      case MULTIPLE:
      case MULTIPLE_USER:
        let modalities = question.possibilities
          .map((a, i) => `${i + 1}. ${a.title}`)
          .join(' - ')

        str = (
          <>
            <span className='text-blue-600'>
              {t('component.questionModal.label')} :{' '}
            </span>
            {modalities}
          </>
        )
        break
      case BLOC_UNIQUE:
      case BLOC_MULTIPLE:
        let col = ''
        let row = ''
        question.columns.forEach((answer, index) => {
          col += answer.title
          if (index < question.columns.length - 1) col += ' / '
        })

        question.rows.forEach((answer, index) => {
          row += answer.title
          if (index < question.rows.length - 1) row += ' / '
        })

        str = (
          <>
            <span className='text-blue-600'>
              {t('component.questionModal.modality_col')} :{' '}
            </span>
            {col}
            <br />
            <span className='text-blue-600'>
              {t('component.questionModal.modality_row')} :{' '}
            </span>
            {row}
          </>
        )
        break

      case NUMERIC:
      case NUMERIC_USER:
        str = t('component.questionModal.types.numeric')

        break
      default:
        str = t('component.questionModal.types.bloc')

        return str
    }

    return str
  }

  return (
    <Modal centered show={show} onHide={onHide} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('component.questionModal.title', {
            title: mappingSurvey(type).title,
            label,
            count: t('survey.nb_questions.nb', { count: nbQuestions }),
          })}
        </Modal.Title>
        <InfoButton
          summary={t('component.questionModal.summary')}
          description={questionnaire.name}
          placement='bottom'
        />
      </Modal.Header>
      <Modal.Body className='px-6 py-3'>
        <Scrollbar
          style={{
            maxHeight: '46.87rem',
            height: window.innerHeight - 250,
            marginRight: '-2rem',
          }}
        >
          <div>
            {sections.map((section, i) => (
              <section key={section._id}>
                <div className='d-flex align-items-center justify-content-between flex-wrap my-4'>
                  <h4>{section.title} :</h4>

                  {i === 0 && (
                    <div className='d-flex'>
                      {canView && (
                        <Button
                          className='m-0 me-5 text-decoration-underline cursor-pointer p-0 fs'
                          onClick={() =>
                            (window.location.href = `/editeur/${questionnaire._id}`)
                          }
                          variant=''
                        >
                          {canEdit
                            ? t('component.questionModal.cta3')
                            : t('component.questionModal.cta4')}
                        </Button>
                      )}

                      <p
                        className='m-0 me-5 text-decoration-underline cursor-pointer text-primary fs'
                        onClick={handleCollapsedAnswersId}
                      >
                        {collapsedAnswerId.length === allQuestionsId.length
                          ? t('component.questionModal.cta1')
                          : t('component.questionModal.cta2')}
                      </p>
                    </div>
                  )}
                </div>

                {section.questions.map((question, i) => (
                  <div key={question._id} className='fs-lg'>
                    <article
                      className={style.article}
                      onClick={() => getAnswerToggler(question._id)}
                    >
                      <p className='m-0'>
                        {i + 1}. {question.question}
                      </p>
                    </article>
                    <div
                      className={classnames(style.answers, {
                        [style.answersVisible]: collapsedAnswerId.includes(
                          question._id,
                        ),
                      })}
                    >
                      <p className={style.modalities}>
                        {formatAnswer(question)}
                      </p>
                    </div>
                  </div>
                ))}
              </section>
            ))}
          </div>
        </Scrollbar>
      </Modal.Body>
    </Modal>
  )
}
