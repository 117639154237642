import React, { useEffect, useMemo, useState } from 'react'
// Redux
import { surveySelector } from '../redux/reducers/surveys'
import { useSelector } from 'react-redux'
// Components
import QuestionnaireCard from '../components/cards/QuestionnaireCard'
import QuestionModal from '../components/modals/QuestionsModal/QuestionModal'
import Section from '../components/layout/section'
import FollowUpSurvey from '../components/cards/FollowUpSurvey'
import UpdateSurveyDatesModal from '../components/modals/UpdatingSurveyDateModal/UpdateSurveyDatesModal'
import ResponseRateCard from '../components/cards/ResponseRateCard'
import { Button, Card } from 'react-bootstrap'
import NoData from '../components/NoData/NoData'
import { ButtonWithIcon } from '@olystic/design_system_3'
import { Link } from 'react-router-dom'
import NavProgressSurvey from '../components/NavProgressSurvey/NavProgressSurvey'
import useModal from '../hooks/useModal'
import TargetCard from '../components/cards/TargetCard'
import RespondentsCard from '../components/cards/RespondentsCard'
import ParticipationRate from '../features/participation/ParticipationRate/ParticipationRateContainer'
import { TYPE_INDIVIDUELLE } from '@olystic/common/survey.mjs'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListUl, faComments } from '@fortawesome/pro-solid-svg-icons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
// Translation
import { useTranslation } from 'react-i18next'

export default function FollowUpPage() {
  const { t } = useTranslation()

  const currentSurveys = useSelector((state) =>
    surveySelector.selectActiveSurveys(state),
  )
  const questionModal = useModal()
  const updateSurveyDatesModal = useModal()
  const [selectedSurveyId, setSelectedSurveyId] = useState(
    currentSurveys[0]?._id,
  )
  const [questionnaire, setQuestionnaire] = useState({})

  useEffect(() => {
    const index = currentSurveys.findIndex((s) => s._id === selectedSurveyId)
    setSelectedSurveyId(currentSurveys[index]?._id)
  }, [currentSurveys, selectedSurveyId])

  useEffect(() => {
    if (selectedSurveyId) {
      const survey = currentSurveys.find((s) => s._id === selectedSurveyId)
      let questionnaire = survey.questionnaire
      questionnaire = { ...questionnaire, actif: true }
      setQuestionnaire(questionnaire)
    }
  }, [currentSurveys, selectedSurveyId])

  const canAddRelance = useMemo(() => {
    const survey = currentSurveys.find((s) => s._id === selectedSurveyId)
    console.log(currentSurveys, selectedSurveyId)
    if (survey) {
      const relanceExeptionnelleKeys = Object.keys(survey?.dates).filter(
        (key) => key.startsWith('RelanceExce'),
      )
      return relanceExeptionnelleKeys.length < 3
    }
  }, [currentSurveys, selectedSurveyId])

  if (!currentSurveys.length)
    return (
      <Section className='mt-9 mx-xxl-9'>
        <Card className='p-7'>
          <NoData
            title={t('component.noQuestionnaireCard.title')}
            text={t('component.noQuestionnaireCard.text')}
            cta={
              <div className='d-flex flex-wrap gap-3'>
                <Link to='/'>
                  <ButtonWithIcon icon={faListUl}>
                    {t('component.noQuestionnaireCard.cta2')}
                  </ButtonWithIcon>
                </Link>

                <ButtonWithIcon
                  icon={faComments}
                  onClick={() => (window.location.href = '/creer-feedback')}
                >
                  {t('component.noQuestionnaireCard.cta3')}
                </ButtonWithIcon>
              </div>
            }
          />
        </Card>
      </Section>
    )

  return (
    <div>
      {currentSurveys.length > 1 && (
        <div className='px-4 border-bottom bg-white'>
          <NavProgressSurvey
            surveys={currentSurveys}
            selectedSurveyId={selectedSurveyId}
            onChangeSurvey={setSelectedSurveyId}
          />
        </div>
      )}

      <div className='d-flex overflow-auto'>
        <div
          className='border-end h-100 px-3 py-5 bg-white d-flex flex-column gap-4'
          style={{ minHeight: '96vh' }}
        >
          {currentSurveys.length === 1 && (
            <h4 className='text-center m-0'>
              {t('page.followUp.aside.title1')}
            </h4>
          )}
          <QuestionnaireCard
            data={questionnaire}
            onShowQuestionsModal={questionModal.onShow}
          />
          <TargetCard surveyId={selectedSurveyId} />

          <h4 className='text-center border-bottom pb-3'>
            {t('page.followUp.aside.title2')}
          </h4>

          <FollowUpSurvey
            surveyId={selectedSurveyId}
            onEdit={updateSurveyDatesModal.onShow}
          />

          {questionnaire.type !== TYPE_INDIVIDUELLE && canAddRelance && (
            <Button className='mx-auto' onClick={updateSurveyDatesModal.onShow}>
              <FontAwesomeIcon icon={faPlus} className='mr-2' />{' '}
              {t('page.followUp.aside.cta')}
            </Button>
          )}
        </div>

        <div className='flex-fill py-5 px-8'>
          <h3 className='mb-5'>{t('page.followUp.title1')}</h3>

          <div className='d-flex flex-wrap gap-5 '>
            <ResponseRateCard surveyId={selectedSurveyId} />
            <RespondentsCard surveyId={selectedSurveyId} />
          </div>

          <h3 className='my-5'>{t('page.followUp.title2')}</h3>
          <ParticipationRate surveyId={selectedSurveyId} />
        </div>
      </div>

      {questionModal.modalProps.show && (
        <QuestionModal
          {...questionModal.modalProps}
          questionnaire={questionnaire}
          canEdit={false}
          canView={false}
        />
      )}

      {updateSurveyDatesModal.modalProps.show && (
        <UpdateSurveyDatesModal
          {...updateSurveyDatesModal.modalProps}
          surveyId={selectedSurveyId}
        />
      )}
    </div>
  )
}
