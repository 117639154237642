import React from "react"
import PropTypes from "prop-types"
import { downloadChart } from "../../../lib/functions"
import LineChart from "../LineChart"
import { Button, Card, Spinner, Tab, Tabs } from "react-bootstrap"
import { useParticipation } from "../../../hooks/useParticipation"
import InteractionIcons from "../../../components/cards/interactionIcons"
import NoData from "../../../components/NoData/NoData"
import ParticipationByCategory from "../ParticipationByCategory/ParticipationByCategory"
import CategoriesSelector from "../ParticipationByCategory/CategoriesSelector/CategoriesSelector"
import { useSelector } from "react-redux"
import { surveySelector } from "../../../redux/reducers/surveys"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartLine } from "@fortawesome/pro-regular-svg-icons"
import { faUsers } from "@fortawesome/pro-duotone-svg-icons"
import { faSyncAlt } from "@fortawesome/pro-light-svg-icons"
// Translation
import { useTranslation } from "react-i18next"

export default function ParticipationRate({
  data,
  dataByCategory,
  info,
  surveyId,
}) {
  const { t } = useTranslation()
  const ref = React.createRef()
  const { isLoading, isError, isSuccess, refetch } = useParticipation(surveyId)
  const [activeTabId, setActiveTabId] = React.useState("1")
  const [categories, setCategories] = React.useState([])
  const [selectedCategory, setSelectedCategory] = React.useState(new Set())
  const maxSize = categories.length
  const survey = useSelector((state) =>
    surveySelector.selectById(state, surveyId)
  )

  const tabs = [
    {
      id: 1,
      name: t("component.participationRate.tabs.tab_1"),
      icon: <FontAwesomeIcon icon={faChartLine} />,
    },
    {
      id: 2,
      name: t("component.participationRate.tabs.tab_2"),
      icon: <FontAwesomeIcon icon={faUsers} />,
    },
  ]

  React.useEffect(() => {
    setCategories(dataByCategory.map((categorie) => categorie.name))
    setSelectedCategory(
      new Set(dataByCategory.map((categorie) => categorie.name))
    )
  }, [data, dataByCategory])

  const selectedDataCategories = React.useMemo(
    () =>
      dataByCategory.filter((category) => selectedCategory.has(category.name)),
    [dataByCategory, selectedCategory]
  )

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center pb-0 px-7 border-bottom">
        <Tabs variant="pills" activeKey={activeTabId} onSelect={setActiveTabId}>
          {tabs.map((tab) => (
            <Tab
              tabClassName="py-3"
              key={tab.id}
              eventKey={tab.id}
              title={
                <div>
                  {tab.icon} {tab.name}
                </div>
              }
            />
          ))}
        </Tabs>
        <div className="center gap-3">
          <Button
            variant
            className="p-0 border-0 text-primary-hover"
            onClick={() => refetch()}
          >
            <FontAwesomeIcon
              icon={faSyncAlt}
              className={`${isLoading && "loadingIcon"} me-1`}
            />
            {t("common.reload")}
          </Button>

          <InteractionIcons
            summary={info.summary}
            description={info.description}
            download={() => downloadChart(ref.current)}
          />
        </div>
      </Card.Header>
      <Card.Body
        className="d-flex p-0 bg-white"
        style={{ height: "25rem" }}
        ref={ref}
      >
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center w-100">
            <Spinner animation={"border"} />
          </div>
        )}
        {isError && (
          <div className="d-flex justify-content-center align-items-center w-100">
            <NoData
              title={t("component.participationRate.no_data.title")}
              cta={
                <Button
                  variant="primary"
                  className="me-auto"
                  onClick={() =>
                    window.open("https:olystic.zendesk.com/hc/fr", "_blank")
                  }
                >
                  {t("common.contact_support")}
                </Button>
              }
            />
          </div>
        )}
        {isSuccess && (
          <>
            {activeTabId === "1" && (
              <LineChart data={data} extraLabel={t("common.day")} />
            )}
            {activeTabId === "2" && !dataByCategory.length && (
              <div className="d-flex justify-content-center w-100">
                <NoData
                  title={t(
                    "component.participationRate.categories.no_data.title"
                  )}
                  text={t(
                    "component.participationRate.categories.no_data.text"
                  )}
                  cta={
                    <Button
                      variant="primary"
                      onClick={() =>
                        (window.location.href = `/rh/${survey.respondentGroup._id}`)
                      }
                    >
                      {t("component.participationRate.categories.no_data.cta")}
                    </Button>
                  }
                />
              </div>
            )}
            {activeTabId === "2" && dataByCategory.length > 0 && (
              <>
                <ParticipationByCategory data={selectedDataCategories} />

                <CategoriesSelector
                  categories={categories}
                  selectedCategories={selectedCategory}
                  onSelectionChange={setSelectedCategory}
                  maxSize={maxSize}
                />
              </>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  )
}

ParticipationRate.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataByCategory: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      responses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  info: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}
