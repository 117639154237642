import html2canvas from 'html2canvas'
import * as htmlToImage from 'html-to-image'
import moment from 'moment'

// download png file from gived url
export function downloadURI(uri, name) {
  var link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadChart = (element) => {
  htmlToImage.toJpeg(element).then(function (dataUrl) {
    downloadURI(dataUrl, 'chart.jpeg')
  })
}

export const downloadChartWithCanva = (element) => {
  html2canvas(element).then((canvas) => {
    const imgData = canvas.toDataURL()
    downloadURI(imgData, 'chart.png')
  })
}

export const downloadTxtFile = (txt) => {
  const link = document.createElement('a')
  const file = new Blob([txt], { type: 'text/plain' })
  link.href = URL.createObjectURL(file)
  link.download = 'message.doc'
  document.body.appendChild(link)
  link.click()
}

export function getDaysBeforeClosing(date) {
  const today = moment()
  return moment(date).diff(today, 'days')
}

export function qualityColor(number) {
  const QUALITY_MAX = 60
  const QUALITY_MIDI = 40

  switch (true) {
    case number > QUALITY_MAX:
      return 'success'
    case number <= QUALITY_MAX && number >= QUALITY_MIDI:
      return 'warning'
    default:
      return 'danger'
  }
}

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1)
}

export const randomId = () => {
  return Math.random().toString(36).substring(7)
}

export const cardinalNumber = (number) => {
  let string
  switch (number) {
    case 1:
      string = 'première'
      break
    case 2:
      string = 'deuxième'
      break
    case 3:
      string = 'troisième'
      break
    case 4:
      string = 'quatrième'
      break
    case 5:
      string = 'cinquième'
      break
    case 6:
      string = 'sixième'
      break
    default:
      string = ''
  }
  return string
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}
