import React, { useState } from 'react'
// Components
import Section from '../components/layout/section'
import { useSelector } from 'react-redux'
import { surveySelector } from '../redux/reducers/surveys'
import { Link, useParams } from 'react-router-dom'
import { Error, notification } from '@olystic/design_system_3'
import { Button, Card } from 'react-bootstrap'
import backend from '../services/backend'
// Translation
import { useTranslation } from 'react-i18next'

const QUESTION = 'question'
const NOTCONFIRMED = 'notConfirmed'
const CONFIRMED = 'confirmed'

export default function Confirm() {
  const { t } = useTranslation()
  const { id } = useParams()
  const [step, setStep] = useState(QUESTION)
  const survey = useSelector((state) => surveySelector.selectById(state, id))

  if (!survey) return <Error status={404} />

  if (survey.confirmed && step !== CONFIRMED)
    return (
      <Error description={t('page.confirm.error', { value: survey.name })} />
    )

  const steps = {
    [QUESTION]: (
      <>
        <h3>
          {t('page.confirm.step.question.question', { value: survey.name })}
        </h3>
        <div className='d-flex align-items-center justify-content-center gap-5 mt-8'>
          <Button
            variant='success'
            className='text-uppercase'
            onClick={() => handleSubmit(true)}
          >
            {t('common.yes')}
          </Button>
          <Button
            variant='danger'
            className='text-uppercase'
            onClick={() => handleSubmit(false)}
          >
            {t('common.no')}
          </Button>
        </div>
      </>
    ),

    [NOTCONFIRMED]: (
      <div>
        <h4>
          {t('page.confirm.step.notConfirmed.text_1', { value: survey.name })}
        </h4>
        <h4 className='mt-5 mb-8'>
          {t('page.confirm.step.notConfirmed.text_2')}
        </h4>
        <Link to='/communications'>
          <Button> {t('page.confirm.step.notConfirmed.cta')}</Button>
        </Link>
      </div>
    ),
    [CONFIRMED]: (
      <div>
        <h4 className='mt-5 mb-8'>{t('page.confirm.step.confirmed.text_1')}</h4>
        <Link to='/calendrier-questionnaires'>
          <Button> {t('page.confirm.step.confirmed.cta')}</Button>
        </Link>
      </div>
    ),
  }
  async function handleSubmit(answer) {
    const payload = { confirmed: answer }
    if (answer) payload.communication = new Date()
    try {
      await backend.surveys.updateSurvey(survey._id, payload)
      notification.success(t('notification.update_survey'), 'lg')
      setStep(answer ? CONFIRMED : NOTCONFIRMED)
    } catch (error) {
      notification.error(t('notification.error'), 'lg')
    }
  }

  return (
    <>
      <div className='border-bottom bg-white p-7 px-9'>
        <h3 className='mb-5'>
          {t('page.confirm.title', { value: survey.name })}
        </h3>
        <p className='w-xxl-50'>
          {t('page.confirm.description_1', { value: survey.name })}
        </p>
        <p className='w-xxl-50'>{t('page.confirm.description_2')}</p>
      </div>

      <Section className='mt-7 w-xl-75 text-center m-auto'>
        <Card className='mt-8 p-8' style={{ minHeight: '23rem' }}>
          <Card.Body>{steps[step]}</Card.Body>
        </Card>
      </Section>
    </>
  )
}
